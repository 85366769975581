<template>
  <div>
    <svg id="map" viewBox="0 0 725 500" style="max-height: 90vh">
      <filter id="shadow" x="0" y="0" width="200%" height="200%">
        <feDropShadow dx="1" dy="1" stdDeviation="1" flood-color="#000000" flood-opacity="1" />
      </filter>
    </svg>

    <v-dialog transition="dialog-top-transition" max-width="600" v-model="popup">
      <v-card>
        <v-toolbar color="primary" dark>
          Judetul {{ selected }}
          <v-spacer></v-spacer>
          <v-btn @click="popup = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <div style="height: 100%; display: flex; flex-direction: column; align-items: center" v-if="selected === 'BACAU'">
            <a href="TUDs_Analytics_Raport_evolutie_bugetara_la_trimestru_4278337_Bacau_Municipiu_2021-T1_ro.xlsx" download class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-file-excel</v-icon>
              Municipiul Bacau - Raport Evolutie Bugetara la Trimestrul 1 2021
            </a>
            <a href="https://buget.verdedata.ro/#/6006d19e1cb1242e9048bd84/comunitatea-locala" class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-monitor-dashboard</v-icon>
              Municipiul Bacau - Mergi la Buget
            </a>
          </div>
          <div style="height: 100%; display: flex; flex-direction: column; align-items: center" v-if="selected === 'CLUJ'">
            <a href="TUDs_Analytics_Raport_evolutie_bugetara_la_trimestru_4378930_Turda_Municipiu_2021-T1_ro.xlsx" download class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-file-excel</v-icon>
              Municipiul Turda - Raport Evolutie Bugetara la Trimestrul 1 2021
            </a>
            <a href="TUDs_Analytics_Raport_evolutie_bugetara_la_trimestru_4354566_Campia Turzii_2021-T1_ro.xlsx" download class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-file-excel</v-icon>
              Municipiul Campia Turzii - Raport Evolutie Bugetara la Trimestrul 1 2021
            </a>
            <a href="https://buget.verdedata.ro/#/5de7aa159683c26b6ff32c75/comunitatea-locala" class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-monitor-dashboard</v-icon>
              Municipiul Turda - Mergi la Buget
            </a>
            <a href="https://buget.verdedata.ro/#/609e39a61b0fe52e96068ba3/comunitatea-locala" class="ma-4 text-decoration-none">
              <v-icon x-large color="primary">mdi-monitor-dashboard</v-icon>
              Municipiul Campia Turzii - Mergi la Buget
            </a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as d3 from 'd3'

const judeteFull = {
  S6: 'SECTOR 6',
  AB: 'ALBA',
  AR: 'ARAD',
  AG: 'ARGES',
  BC: 'BACAU',
  BH: 'BIHOR',
  BN: 'BISTRITA-NASAUD',
  BT: 'BOTOSANI',
  BR: 'BRAILA',
  BV: 'BRASOV',
  B: 'MUNICIPIUL BUCURESTI',
  BZ: 'BUZAU',
  CL: 'CALARASI',
  CS: 'CARAS-SEVERIN',
  CJ: 'CLUJ',
  CT: 'CONSTANTA',
  CV: 'COVASNA',
  DB: 'DAMBOVITA',
  DJ: 'DOLJ',
  GL: 'GALATI',
  GR: 'GIURGIU',
  GJ: 'GORJ',
  HR: 'HARGHITA',
  HD: 'HUNEDOARA',
  IL: 'IALOMITA',
  IS: 'IASI',
  IF: 'ILFOV',
  MM: 'MARAMURES',
  MH: 'MEHEDINTI',
  MS: 'MURES',
  NT: 'NEAMT',
  OT: 'OLT',
  PH: 'PRAHOVA',
  SJ: 'SALAJ',
  SM: 'SATU MARE',
  SB: 'SIBIU',
  SV: 'SUCEAVA',
  TR: 'TELEORMAN',
  TM: 'TIMIS',
  TL: 'TULCEA',
  VL: 'VALCEA',
  VS: 'VASLUI',
  VN: 'VRANCEA'
}

export default {
  name: 'helloworld',
  data () {
    return {
      popup: false,
      selected: null
    }
  },
  methods: {
    async draw () {
      const $vm = this
      const judete = await d3.json('judete.json')
      // judete.features = judete.features.splice(41)

      const projection = d3.geoMercator().fitSize(['650', '500'], judete)

      const path = d3.geoPath()
        .projection(projection)

      const labelsData = []

      judete.features.forEach(judet => {
        const abr = judet.id
        judet.id = judeteFull[judet.id]
        judet.label = judet.id
        judet.center = {
          x: path.centroid(judet)[0],
          y: path.centroid(judet)[1]
        }

        labelsData.push({
          id: judet.id,
          x: judet.center.x,
          y: judet.center.y,
          label: abr
        })
      })

      // const color = d3.scaleSequential().domain([0, 100])
      //   .interpolator(d3.interpolateRdYlGn)

      const color = d3.scaleLinear()
        .range(['#257abf', '#0fb693'])
        .domain([0, 100])
        .interpolate(d3.interpolateHcl)

      const svg = d3
        .select('svg#map')
        .append('g')
        .attr('id', 'mapgroup')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('transform', 'translate(25, 0)')

      const paths = svg
        .selectAll('path.judet')
        .data(judete.features)
        .enter()
        .append('path')
        .attr('class', 'judet')
        .attr('id', d => d.id)
        .attr('d', path)
        .attr('fill', d => {
          const ifb = d.id === 'MUNICIPIUL BUCURESTI' || d.id === 'ILFOV'
          return ifb ? color(50) : color(Math.random() * 100)
        })
        // .attr('transform-origin', 'center center')
        .attr('transform', d => {
          // const x = Math.random() * 800 - 400
          // const y = Math.random() * 800 - 400
          // return `translate(${x}, ${y}) scale(.1)`
          // return 'scale(.1)'
        })

      paths
        .transition('scale-in')
        .duration(5000)
        .delay((d, i) => i * 10)
        .ease(d3.easePolyInOut)
        // .attr('transform', 'translate(0, 0) scale(1)')
        // .attr('transform-origin', 'center center')
        // .attr('transform', 'scale(1)')

      paths.on('mouseover', function (node, index) {
        d3
          .select(this)
          .attr('stroke', 'black')
          .attr('stroke-width', 1)
          // .style('filter', 'url(#shadow)')
      })
      paths.on('mouseout', function (node, index) {
        d3
          .select(this)
          .attr('stroke', null)
          // .style('filter', 'none')
      })
      paths.on('click', function (node, index) {
        const id = d3.select(this).attr('id')
        if (id === 'BACAU' || id === 'CLUJ') {
          $vm.selected = id
          $vm.popup = true
        }
      })

      paths.each(function (d, i) {
        const bbox = d3.select(this).node().getBBox()
        judete.features.forEach(judet => {
          if (judet.id === d.id) {
            judet.width = bbox.width
          }
        })
        labelsData.forEach(label => {
          if (label.id === d.id) {
            label.width = bbox.width
          }
        })
      })

      // const labels = svg
      svg
        .selectAll('.label')
        .data(labelsData)
        .enter()
        .append('text')
        .attr('class', 'label')
        .attr('id', d => `${d.id}-label`)
        .text(d => d.label)
        .attr('font-size', '10px')
        .style('fill', 'transparent')
        .style('text-shadow', '2px 2px 2px black')
        .style('text-anchor', 'middle')
        .style('alignment-baseline', 'middle')
        .attr('x', d => d.x)
        .attr('y', d => d.y)
        .call(wrap)
        .transition('labels-in')
        .duration(1000)
        .delay(1000)
        .ease(d3.easePolyInOut)
        .style('fill', '#fff')

      function wrap (selection) {
        selection.each(function (d, i) {
          const width = d.width - 20
          const text = d3.select(this)
          const words = text.text().split(/\s+/).reverse()
          let word
          let line = []
          let lineNumber = 0
          const lineHeight = 1.1
          const y = text.attr('y')
          const dy = 0 // parseFloat(text.attr('dy'))
          let tspan = text.text(null).append('tspan').attr('x', d.x).attr('y', y).attr('dy', dy + 'em')
          /* eslint-disable no-cond-assign */
          while (word = words.pop()) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = text.append('tspan').attr('x', d.x).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word)
            }
          }
        })
      }
    }
  },
  async mounted () {
    this.draw()
  }
}
</script>

<style>
  .judet {
    /* stroke: black; */
    /* stroke-width: 0.25; */
  }

  #dialog {
    width: 400px;
    height: 400px;
    border: thin solid red;
  }
</style>
