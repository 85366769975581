<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-3">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class=""
          contain
          src="https://tudconsult.ro/wp-content/uploads/2018/07/logo-tud.png"
          transition="scale-transition"
          width="100"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld'

export default {
  name: 'App',

  components: {
    HelloWorld
  },

  data: () => ({
    //
  })
}
</script>
